<template>
	<div class="potential contractDetail">
		<div ref="navBar">
			<rxNavBar title="合同详情"></rxNavBar>
		</div>
		<p class="application_time">
			<!--申请时间：-->
			<!--<span>{{type=='2'?contractInfo.contractBeginTime:contractInfo.renterContractBeginTime}}</span>-->
			签约日期：
			<span>{{contractInfo.signTime ? contractInfo.signTime:'暂无'}}</span>
		</p>
		<div class="panel withoutMargin">
			<!-- 在租中 -->
			<div class="state stateGreen" v-if="contractStatus!='4'&&contractStatus!='5'&&contractStatus!='7'">
				{{contractTypeVal}}
			</div>
			<!-- 代签字 -->
			<div class="state stateOrange" v-if="contractStatus=='5'">
				{{contractTypeVal}}
			</div>
			<!-- 已作废 已作废 -->
			<div class="state stateGray" v-if="contractStatus=='7'|| contractStatus=='4'">
				{{contractTypeVal}}
			</div>
			<div class="title">
				<p class="van-multi-ellipsis--l2">{{contractInfo.houseAddress}}</p>
			</div>
			<van-row class="info">
				<van-col span="12">
					<p>
						{{type=='2'?'业主':'租客'}}：
						<span>{{type=='2'?contractInfo.ownerName:contractInfo.renterName}}</span>
					</p>
				</van-col>
				<van-col span="12">
					<p>
						电话：
						<span>{{type=='2'?contractInfo.ownerMobile:contractInfo.renterMobile}}</span>
					</p>
				</van-col>
			</van-row>
			<div class="info">
				<p>
					<span>{{type=='2'?contractInfo.contractBeginTime:contractInfo.renterContractBeginTime}}
						至
						{{type=='2'?contractInfo.contractEndTime:contractInfo.renterContractEndTime}}
					</span>
				</p>
				<p>
					<span>{{type=='2'?contractInfo.rentMoney:contractInfo.renterRentMoney}}元/月</span>
					<span> {{type=='2'?contractInfo.payTypeName:contractInfo.renterPayTypeName}}</span>
				</p>
				<p>
					<van-tag color="rgb(250,223,209)" text-color="rgba(255, 93, 59, 1)" style="marginRight:8px">{{historyType}}</van-tag>
					<!--          <van-tag color="rgb(219,234,252)" text-color="rgba(86, 155, 236, 1)">{{contractInfo.signTypeVal}}</van-tag>-->
					<van-tag color="rgb(219,234,252)" v-if="contractInfo.contractType == '1'" text-color="rgba(86, 155, 236, 1)">{{contractInfo.signType=='1'?'线上签约':'线下签约'}}</van-tag>
				</p>
			</div>
		</div>
		<div class="tabWrapper">
			<van-tabs v-model="tabActive" line-width="20" line-height="4" :lazy-render="false" title-active-color="rgb(255,81,45) " @click="onClick">
				<van-tab :title="type=='2'?'业主':'租客'">
					<!-- 租客内容信息-->
					<span class="contractOrLeaseChange" v-if="type=='1'">租客信息</span>
					<div class="panel" v-if="type=='1'">
						<van-row type="flex" align="center" class="info">
							<van-col span="5">
								<van-image round width="50" height="50" fit="contain" style="background-color: #e1eaec;" :src="contractInfo.renterIconPath?contractInfo.renterIconPath:userDefaultHeadImg" />
							</van-col>
							<van-col>
								<p class="tenant">
									<span>{{contractInfo.renterName}}</span>
									<span class="male" v-if="contractInfo.renterGender=='1'">男</span>
									<span class="female" v-if="contractInfo.renterGender=='0'">女</span>
								</p>
								<p>{{contractInfo.ownerMobile}}</p>
							</van-col>
						</van-row>
						<div class="info">
							<p>
								身份证号：
								<span>{{contractInfo.renterCertificateCode}}</span>
							</p>
							<p>
								职业：
								<span>{{contractInfo.occupation_id?contractInfo.occupation_id:'暂无'}}</span>
							</p>
							<p>
								爱好：
								<span>{{contractInfo.hobby?contractInfo.hobby:'暂无'}}</span>
							</p>
						</div>
						<div class="info" v-if="ownerPhoto.length>0">
							<van-row gutter="10">
								<van-col v-for="(idcardImg,i) in ownerPhoto" :key="i" :span="7" @click="previewImg(i)">
									<van-image radius="8" fit="contain" style="background-color: #e1eaec;" height="50" :src="idcardImg" />
								</van-col>
							</van-row>
						</div>
						<div class="info">
							<p>
								紧急联系人：
								<span>{{contractInfo.renterEmergencyContactPerson}}</span>
								<span class="hasPadding">{{contractInfo.renterEmergencyContactPhone}}</span>
							</p>
							<p>
								紧急联系邮箱：
								<span>{{contractInfo.renterEmergencyContactEmail}}</span>
							</p>
							<p>
								紧急联系地址：
								<span>{{contractInfo.renterEmergencyContactAddress}}</span>
							</p>
						</div>
					</div>
					<!-- 同住人内容信息-->
					<span class="contractOrLeaseChange" v-if="contractInfo.cohabitMovle != undefined">同住人信息</span>
					<div class="panel" v-if="type=='1' && contractInfo.cohabitMovle != undefined">
						<van-row type="flex" align="center" class="info">
							<van-col>
								<p class="tenant">
									<span>{{contractInfo.cohabitName}}</span>
									<span class="male">{{contractInfo.cohabitSex}}</span>
								</p>
								<p>{{contractInfo.cohabitMovle}}</p>
								<div class="info">
									<p>
										证件号：
										<span>{{contractInfo.cohabitCertificateCode}}</span>
									</p>
								</div>
							</van-col>
						</van-row>
					</div>
					<!-- 业主内容信息-->
					<div v-if="type==2">
					<span class="contractOrLeaseChange">业主信息</span>
					<div class="panel">
						<van-row type="flex" align="center" class="info">
							<van-col span="5">
								<van-image round fit="contain" style="background-color: #e1eaec;" width="50" height="50" :src="contractInfo.iconPath ? contractInfo.iconPath : userDefaultHeadImg" />
							</van-col>
							<van-col>
								<p class="tenant">
									{{contractInfo.ownerName}}
									<span class="male" v-if="contractInfo.ownerGender=='1'">男</span>
									<span class="female" v-if="contractInfo.ownerGender=='0'">女</span>
								</p>
								<p>{{contractInfo.ownerMobile}}</p>
							</van-col>
						</van-row>
						<div class="info">
							<p>
								{{contractInfo.ownerCertificateTitle ? contractInfo.ownerCertificateTitle + ':':'未知'}}
								<span>{{contractInfo.ownerCertificateCode}}</span>
							</p>
							<p>
								职业：
								<span>{{contractInfo.occupation_id?contractInfo.occupation_id:'暂无'}}</span>
							</p>
							<p>
								爱好：
								<span>{{contractInfo.hobby ? contractInfo.hobby : '暂无'}}</span>
							</p>
						</div>
						<div class="info" v-if="ownerPhoto.length>0">
							<van-row gutter="10">
								<van-col v-for="(idcardImg,i) in ownerPhoto" :key="i" :span="7" @click="previewImg(i)">
									<van-image radius="8" fit="contain" style="background-color: #e1eaec;" height="50" :src="idcardImg" />
								</van-col>
							</van-row>
						</div>
						<div class="info">
							<p>
								紧急联系人：
								<span>{{contractInfo.emergencyContactPerson}}</span>
								<span class="hasPadding">{{contractInfo.emergencyContactPhone}}</span>
							</p>
							<p>
								紧急联系邮箱：
								<span>{{contractInfo.emergencyContactEmail}}</span>
							</p>
							<p>
								紧急联系地址：
								<span>{{contractInfo.emergencyContactAddress}}</span>
							</p>
						</div>
					</div>
					<!--代理人信息-->
					<span class="contractOrLeaseChange" v-if="contractInfo.hasAgent=='true'">代理人信息</span>
					<div class="panel" v-if="contractInfo.hasAgent=='true'">
						<van-row type="flex" align="center" class="info">
							<van-col span="5">
								<van-image round width="50" height="50" fit="contain" style="background-color: #e1eaec;" :src="contractInfo.agentIconPath?contractInfo.agentIconPath:userDefaultHeadImg" />
							</van-col>
							<van-col>
								<p class="tenant">
									{{contractInfo.agentName}}
									<span class="male" v-if="contractInfo.agentGender=='1'">男</span>
									<span class="female" v-if="contractInfo.agentGender=='0'">女</span>
									<span class="female" v-if="contractInfo.agentGender=='2'">未知</span>
								</p>
								<p>{{contractInfo.agentMobile}}</p>
							</van-col>
						</van-row>
						<div class="info">
							<p>
								身份证号：
								<span>{{contractInfo.agentCertificateCode}}</span>
							</p>
						</div>
						<div class="info" v-if="proxyPhoto.length>0">
							<van-row gutter="10">
								<van-col v-for="(idcardImg,i) in proxyPhoto" :key="i" :span="7" @click="previewCoImg(i)">
									<van-image radius="8" fit="contain" style="background-color: #e1eaec;" height="50" :src="idcardImg" />
								</van-col>
							</van-row>
						</div>
					</div>
					</div>

				</van-tab>
				<!-- 业主-->
				<van-tab v-if="type=='2'" title="合同">
					<div class="panel refund">
						<van-row class="info">
							<van-col span="24">
								<p>
									产权地址:
									<span>{{contractInfo.propertyRightAddress}}</span>
								</p>
							</van-col>
							<van-col span="24">
								<p>
									交房日期:
									<span>{{contractInfo.giveHouseTime}}</span>
								</p>
							</van-col>
							<van-col span="24">
								<p>
									合同期限:
									<span>{{contractInfo.contractBeginTime}}</span>
									<span class="hasPadding">至</span>
									<span>{{contractInfo.contractEndTime}}</span>
								</p>
							</van-col>
							<van-col span="24">
								<p>
									装修期限:
									<span>{{contractInfo.renovationBeginTime}}</span>
									<span class="hasPadding">至</span>
									<span>{{contractInfo.renovationEndTime}}</span>
								</p>
							</van-col>
							<van-col span="24">
								<p>
									合同编号:
									<span class="total_color">{{contractInfo.contractCode}}</span>
								</p>
							</van-col>
							<van-col span="12">
								<p>
									免租期类型:
									<span>{{contractInfo.noFeeTypeVal}}</span>
								</p>
							</van-col>
							<van-col span="12">
								<p>
									租金:
									<span>{{contractInfo.rentMoney}}元/月</span>
								</p>
							</van-col>
							<van-col span="12">
								<p>
									付款方式:
									<span>{{contractInfo.payTypeName}}</span>
								</p>
							</van-col>
<!--							<van-col span="12">-->
<!--								<p>-->
<!--									押金:-->
<!--									<span>{{contractInfo.depositMoney?contractInfo.depositMoney+'元/月':'暂无'}}</span>-->
<!--								</p>-->
<!--							</van-col>-->

							<van-col span="12">
								<p>
									合同年限:
									<span>{{contractInfo.contractYears}}年</span>
								</p>
							</van-col>

							<van-col span="12">
								<p>
									权属类型:
									<span>{{contractInfo.propertyRightTypeVal}}</span>
								</p>
							</van-col>
							<van-col span="24">
								<p>
									产权证号:
									<span>{{contractInfo.propertyRightCode}}</span>
								</p>
							</van-col>
							<van-col span="12">
								<p>
									面积:
									<span>{{contractInfo.builtUpArea}}㎡</span>
								</p>
							</van-col>

							<van-col span="12">
								<p>
									朝向:
									<span>{{contractInfo.orientationVal}}</span>
								</p>
							</van-col>
							<van-col span="12">
								<p>
									供暖情况:
									<span>{{contractInfo.heatingModeVal?contractInfo.heatingModeVal:'暂无'}}</span>
								</p>
							</van-col>
							<van-col span="12">
								<p>
									抵押情况:
									<span>{{contractInfo.mortgageSituationVal?contractInfo.mortgageSituationVal:'暂无'}}</span>
								</p>
							</van-col>

						</van-row>
						<div class="info">
							<p class="total_color">
								<span>签约人：<span class="total_color hasPadding"></span>{{contractInfo.sinName}}</span>
								<span class="hasPadding">|</span>
								<span>{{contractInfo.sinDepartmentName}}</span>
								<span class="floatR small_font">{{contractInfo.applicationTime}}</span>
							</p>
							<!--             partnerDepartmentName -->
							<p class="total_color" v-if="contractInfo.partnerStaff_id">
								<span>合作人：<span class="total_color hasPadding"></span>{{contractInfo.parUserName}}<span class="hasPadding">|</span>{{contractInfo.cooperationDepartmentName}}
								</span>
							</p>
							<p v-if="contractInfo.partnerStaff_id"><span>合作占比：{{contractInfo.pardicDescription}}</span></p>
						</div>
					</div>
					<div class="panel" v-if="enclosurePropertyPicList.length>0">
						产权证明
						<div class="swiper_show" ref="wrapperBox1">
							<div class="content">
								<div class="imgWrap" v-for="(url,i) in enclosurePropertyPicList" :key="i" @click="enclosureviewImg(i)">
									<van-image width="100" style="background-color: #e1eaec;" height="75" fit="contain" radius="10" :src="url" />
								</div>
							</div>
							<div class="num">{{enclosurePropertyPicList.length}}图</div>
						</div>
					</div>
					<div class="panel" v-if="outLinecontratPicList.length>0">
						线下合同
						<div class="swiper_show" ref="wrapperBox2">
							<div class="content">
								<div class="imgWrap" v-for="(url,i) in outLinecontratPicList" :key="i" @click="outLinecontratviewImg(i)">
									<van-image width="100" height="75" fit="contain" style="background-color: #e1eaec;" radius="10" :src="url" />
								</div>
							</div>
							<div class="num">{{outLinecontratPicList.length}}图</div>
						</div>
					</div>
				</van-tab>
				<!-- 租客-->
				<van-tab v-if="type=='1'" title="合同">
					<div class="panel refund">
						<van-row class="info">
							<van-col span="24">
								<p>
									租期:
									<span>{{contractInfo.renterContractBeginTime}}</span>
									<span class="hasPadding">至</span>
									<span>{{contractInfo.renterContractEndTime}}</span>
								</p>
							</van-col>
							<van-col span="24">
								<p>
									合同编号：
									<span class="total_color">{{contractInfo.contractCode}}</span>
								</p>
							</van-col>
<!--							<van-col span="12">-->
<!--								<p>-->
<!--									成交方式：-->
<!--									<span>{{contractInfo.signTypeVal}}</span>-->
<!--								</p>-->
<!--							</van-col>-->
							<van-col span="12">
								<p>
									租金：
									<span>{{contractInfo.renterRentMoney}}元/月</span>
								</p>
							</van-col>
							<van-col span="12">
								<p>
									付款方式:
									<span style="margin-left: 8px;">{{contractInfo.renterPayTypeName}}</span>
								</p>
							</van-col>
							<van-col span="12">
								<p>
									押金：
									<span>{{contractInfo.renterDepositMoney}}元/月</span>
								</p>
							</van-col>
							<van-col span="12">
								<p>
									物品押金：
									<span>{{contractInfo.goodsPrice?contractInfo.goodsPrice:0}}元/月</span>
								</p>
							</van-col>							

						</van-row>
						<div class="info">
							<p class="total_color">
								<span>签约人：<span class="total_color hasPadding"></span>{{contractInfo.sinName}}</span>
								<span class="hasPadding">|</span>
								<span>{{contractInfo.sinDepartmentName}}</span>
								<span class="floatR small_font">{{contractInfo.applicationTime}}</span>
							</p>
							<p class="total_color" v-if="contractInfo.partnerStaff_id">
								<span>合作人：<span class="total_color hasPadding"></span>{{contractInfo.parUserName}}<span class="hasPadding">|</span>{{contractInfo.cooperationDepartmentName}}
								</span>
							</p>
							<p v-if="contractInfo.partnerStaff_id"><span>合作占比：{{contractInfo.pardicDescription}}</span></p>




							<!--              <p class="total_color">-->
							<!--                签约人：{{contractInfo.sinName}}-->
							<!--                <span class="hasPadding">|</span>-->
							<!--                <span>{{contractInfo.sinDepartmentName}}</span>-->
							<!--                <span class="floatR small_font">{{contractInfo.applicationTime}}</span>-->
							<!--              </p>-->
							<!--              <p class="small_font" v-if="contractInfo.partnerStaff_id">-->
							<!--                <span>合作- <span class="total_color hasPadding">{{contractInfo.pardicDescription}}</span>{{contractInfo.parUserName}}<span class="hasPadding">|</span>{{contractInfo.cooperationDepartmentName}} </span>-->
							<!--              </p>-->
						</div>
					</div>
					<div class="panel" v-if="enclosurePropertyPicList.length>0">
						合同附件
						<div class="swiper_show" ref="wrapperBox1">
							<div class="content">
								<div class="imgWrap" v-for="(url,i) in enclosurePropertyPicList" :key="i" @click="enclosureviewImg(i)">
									<van-image width="100" style="background-color: #e1eaec;" height="75" fit="contain" radius="10" :src="url" />
								</div>
							</div>
							<div class="num">{{enclosurePropertyPicList.length}}图</div>
						</div>
					</div>
				</van-tab>

				<van-tab title="免租期" v-if="noFreeList.length>0">
					<div v-if="noFreeList.length>0">
						<div class="freeTime" v-for="(item,index) in noFreeList" :key="index">
							<div class="freeOne">
								<div class="freeOne-title">第{{index+1}}年<span>: {{item.noFeeMonthLong}}月{{item.noFeeTimeLong}}天</span></div>
								<div class="freeOne-time">免租期限:<span> {{timestampToTime(item.noFeeBeginTime)}} 至
										{{timestampToTime(item.noFeeEndTime)}}</span></div>
								<div class="freeOne-time">委托期限:<span> {{timestampToTime(item.everyYearEntrustBeginTime)}} 至
										{{timestampToTime(item.everyYearEntrustEndTime)}}</span></div>
							</div>
						</div>
						<div class="totalTime">
							<div class="totalTitle">总免租期</div>
							<div class="totalLine"></div>
							<div class="totalDays">{{getTotalNoFreeMonth()}}月{{getTotalNoFreeDays()}}天</div>
							<div class="totalDesc"></div>
						</div>
					</div>
				</van-tab>

				<van-tab title="交割">
					<!--  合同的  -->
					<!-- <div v-if="showProperty&&DO.type=='1'"> -->
					<div v-if="ww1 && contractProperty ">
						<span class="contractOrLeaseChange">签约物业交割</span>
						<div class="panel">
							<div class="info" v-if="contractProperty.wattMeterNumber">
								<p>电表</p>
								<p>
									<span>表号：</span>
									<span>{{contractProperty.wattMeterCode?contractProperty.wattMeterCode:'暂无'}}</span>
									<span class="hasPadding">/</span>
									<span>底表数：</span>
									<span>{{contractProperty.wattMeterNumber}}</span>
								</p>
							</div>
							<div class="info" v-if="contractProperty.waterMeterNumber">
								<p>水表</p>
								<p>
									<span>表号：</span>
									<span>{{contractProperty.waterMeterCode?contractProperty.waterMeterCode:'暂无'}}</span>
									<span class="hasPadding">/</span>
									<span>底表数：</span>
									<span>{{contractProperty.waterMeterNumber}}</span>
								</p>
							</div>
							<div class="info" v-if="contractProperty.gasMeterNumber">
								<p>燃气表</p>
								<p>
									<span>表号：</span>
									<span>{{contractProperty.gasMeterCode?contractProperty.gasMeterCode:'暂无'}}</span>
									<span class="hasPadding">/</span>
									<span>底表数：</span>
									<span>{{contractProperty.gasMeterNumber}}</span>
								</p>
							</div>
						</div>
						<div class="panel" v-if="contractProperty.description">
							<div class="bz">交割备注:&nbsp;</div><span class="span-description">{{contractProperty.description}}</span>
						</div>
						<div>
							<div class="panel" v-if="contractPropertyDetail.length>0">
								<div class="info" v-for="(item,index) in contractPropertyDetail" :key="index">
									<div class="info"  v-for="(items,index) in item.sublist" :key="index">
									<p>
										{{item.name}} -
										{{items.itemName}}
										<span class="DO_link" @click="showPropertyDetailPhotos(items)">查看实物图></span>
									</p>
									<p>
										<span>描述：</span>
										<span>{{items.itemDescription}}</span>
									</p>
									</div>
								</div>
							</div>
						</div>
						<!--配置物品弹出层-->
						<van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
							<div class="swiper_show" ref="wrapperBox">
								<div class="content">
									<div class="imgWrap" v-for="(url,i) in propertyDetailPhotos" :key="i" @click="previewPropertyDetailPhotos(i)">
										<van-image width="100" height="75" fit="contain" radius="10" :src="url" />
									</div>
								</div>
								<div class="num">{{propertyDetailPhotos.length}}图</div>
							</div>
						</van-popup>
					</div>

					<!--  租约变更的  -->
					<div class="aa" v-if="ww2 && tenantContractProperty">
						<span class="contractOrLeaseChange">解除物业交割</span>
						<div class="panel">
							<div class="info" v-if="tenantContractProperty.wattMeterNumber">
								<p>电表</p>
								<p>
									<span>表号：</span>
									<span>{{tenantContractProperty.wattMeterCode?tenantContractProperty.wattMeterCode:'暂无'}}</span>
									<span class="hasPadding">/</span>
									<span>底表数：</span>
									<span>{{tenantContractProperty.wattMeterNumber}}</span>
								</p>
							</div>
							<div class="info" v-if="tenantContractProperty.waterMeterNumber">
								<p>水表</p>
								<p>
									<span>表号：</span>
									<span>{{tenantContractProperty.waterMeterCode?tenantContractProperty.waterMeterCode:'暂无'}}</span>
									<span class="hasPadding">/</span>
									<span>底表数：</span>
									<span>{{tenantContractProperty.waterMeterNumber}}</span>
								</p>
							</div>
							<div class="info" v-if="tenantContractProperty.gasMeterNumber">
								<p>燃气表</p>
								<p>
									<span>表号：</span>
									<span>{{tenantContractProperty.gasMeterCode?tenantContractProperty.gasMeterCode:'暂无'}}</span>
									<span class="hasPadding">/</span>
									<span>底表数：</span>
									<span>{{tenantContractProperty.gasMeterNumber}}</span>
								</p>
							</div>
						</div>
						<div class="panel" v-if="tenantContractProperty.description">
							<div class="bz">交割备注:&nbsp;</div><span class="span-description">{{tenantContractProperty.description}}</span>
						</div>
						<div>
							<div class="panel" v-if="tenantPropertyDetail.length>0">
								<div class="info" v-for="(item,index) in tenantPropertyDetail" :key="index">
									<p>
										{{item.name}}
										<span class="DO_link" @click="showLeasePropertyDetailPhotos(item)">查看实物图></span>
									</p>
									<p>
										<span>描述：</span>
										<span>{{item.sublist[0].itemDescription}}</span>
									</p>
								</div>
							</div>
						</div>
						<!--配置物品弹出层-->
						<van-popup v-model="showLease" position="bottom" :style="{ height: '30%' }">
							<div class="swiper_show" ref="wrapperBox">
								<div class="content">
									<div class="imgWrap" v-for="(url,i) in leasePropertyDetailPhotos" :key="i" @click="previewLeasePropertyDetailPhotos(i)">
										<van-image width="100" height="75" fit="contain" radius="10" :src="url" />
									</div>
								</div>
								<div class="num">{{leasePropertyDetailPhotos.length}}图</div>
							</div>
						</van-popup>
					</div>
					<!--空状态图-->
					<common-empty v-if="!ww1&&!ww2"></common-empty>
				</van-tab>
				<van-tab style="margin-left: 10px" title="账单">
					<div class="bill" v-if="bill.length>0">
						<div v-for="(item,i) in bill" :key="i">
							<div class="billPayTime">{{item.billFeeType_id == '2' && (item.billStatus == 2 || item.billStatus == 3) ? item.billEndTime : item.billPayTime}}</div>
							<div class="bill_panel">
								<van-row class="bill_info" type="flex" align="center">
									<!-- 四种状态 待确认billStatus 账单状态(0 应收=应支 1已收=已支 2 应付=应收 3 已付=已收 4 停付) 作为业主 对应的是海米  应该支付给海米（应支） 134 金额浅色-->
									<div class="laber " v-if="item.billStatus==0">
										<div class="laberText  ">应收</div>
									</div>
									<div class="laber " v-if="item.billStatus==1">
										<div class="laberText ">已收</div>
									</div>
									<div class="laber " v-if="item.billStatus==2">
										<div class="laberText ">应支</div>
									</div>
									<div class="laber " v-if="item.billStatus==3">
										<div class="laberText ">已支</div>
									</div>
									<div class="laber " v-if="item.billStatus==4">
										<div class="laberText ">停付</div>
									</div>
									<van-col span="19">
										<p>
											{{item.dictionaryTitle}}
											<span>{{item.billDescription ? '(' + item.billDescription + ')' : ''}}</span>
										</p>
										<p class="small">
											<span>费用周期：</span>
											<span>{{item.billBeginTime}}</span>
											<span class="hasPadding">至</span>
											<span>{{item.billEndTime}}</span>
										</p>
									</van-col>

									<van-col span="5">
										<p :class="item.billStatus=='1' || item.billStatus=='3' || item.billStatus=='4' ? 'rentPriceA':' total_color bill_money'">
											{{item.totalAmount}}元
										</p>
									</van-col>
								</van-row>
							</div>
						</div>
					</div>
					<!--空状态图-->
					<common-empty v-if="bill.length==0"></common-empty>
				</van-tab>
				<van-tab title="退租" v-if="contractHistoryId">
					<div class="panel refund" v-if="contractHistoryId">
						<div class="info" v-if="type==1">
							<p>
								剩余租金：
								<span>{{withoutAny.remainRentMoney == undefined? '暂无' : withoutAny.remainRentMoney+'元'}}</span>
							</p>
							<p>
								剩余水电：
								<span>{{withoutAny.restWaterElectric == undefined? '暂无' : withoutAny.restWaterElectric}}</span>
							</p>
							<p>
								剩余押金：
								<span>{{withoutAny.surplusDepositMoney == undefined ? '暂无' : withoutAny.surplusDepositMoney+'元'}}</span>
							</p>
							<p>
								收(支)违约金：
								<span>{{withoutAny.falsify == undefined? '暂无' : withoutAny.falsify+'元'}}</span>
							</p>
							<p>
								滞纳金：
								<span>{{withoutAny.lateFee== undefined? '暂无' : withoutAny.lateFee+'元'}}</span>
							</p>
							<p>
								装修赔偿：
								<span>{{withoutAny.payRepair == undefined? '暂无' : withoutAny.payRepair+'元'}}</span>
							</p>
							<p>
								预留水电费：
								<span>{{withoutAny.reserveWaterElectric == undefined? '暂无' : withoutAny.reserveWaterElectric+'元'}}</span>
							</p>
							<p>
								利息：
								<span>{{withoutAny.interest == undefined? '暂无' : withoutAny.interest+'元'}}</span>
							</p>
							<p>
								租客是否补款{{withoutAny.isUserPayed?withoutAny.isUserPayed=='0'?'(无需补款)':'(需补款)':'(无需补款)'}}：
<!--								<span>{{withoutAny.isUserPayed=='0'?'暂无':withoutAny.UserPayedMoney?withoutAny.UserPayedMoney+'元':'暂无'}}</span>-->
								<span>{{withoutAny.UserPayedMoney == undefined? '暂无' : withoutAny.UserPayedMoney+'元'}}</span>
							</p>
							<p>
								实际违约金：
								<span>{{withoutAny.realFalsify == undefined? '暂无' : withoutAny.realFalsify+'元'}}</span>
							</p>
							<p>
								应退总额：
								<span>{{withoutAny.totalRefunded?withoutAny.totalRefunded+'元':'暂无'}}</span>
							</p>
						</div>
						<div class="info" v-if="type==2">
							<p>
								业主退回房租：
								<span>{{withoutAny.remainRentMoney ==undefined? '暂无' : withoutAny.remainRentMoney+'元'}}</span>
							</p>
							<p>
								支付业主房租：
								<span>{{withoutAny.surplusDepositMoney  ==undefined ? '暂无' : withoutAny.surplusDepositMoney+'元'}}</span>
							</p>
							<p>
								违约金：
								<span>{{withoutAny.realFalsify  == undefined? '暂无' : withoutAny.realFalsify+'元'}}</span>
							</p>
							<p>
								装修赔偿：
								<span>{{withoutAny.payRepair  == undefined? '暂无' : withoutAny.payRepair+'元'}}</span>
							</p>
							<p>
								公司退回总额：
								<span>{{withoutAny.userTotalPay == undefined? '暂无' : withoutAny.userTotalPay+'元'}}</span>
							</p>
							<p>
								业主退回总额：
								<span>{{withoutAny.realBreakMoney == undefined? '暂无' : withoutAny.realBreakMoney+'元'}}</span>
							</p>
						</div>
						<div class="info">
							<p>补充说明：</p>
							<p>
								<span>{{withoutAny.remarks}}</span>
							</p>
						</div>
					</div>
				</van-tab>
				<van-tab title="审批" v-if="approvalHistroyList.length>0&&mode=='1'">
					<div class="approve" v-if="approvalHistroyList.length>0&&mode=='1'">
						<div class="time_line">
							<div class="item" v-for="(item,index) in approvalHistroyList" :key="index">
								<div class="left">
									<div :class="{line:true ,line_top:true,first_top:index==0}"></div>
									<div class="point"></div>
									<div class="line line_bottom" v-if="index != approvalHistroyList.length - 1"></div>
								</div>
								<div class="right">
									<div class="approve_panel">
										<p class="total_color">
											{{item.approvalResult=='0'?'同意':'拒绝'}}
											<span class="floatR small_font">{{item.addTime}}</span>
										</p>
										<p>
											{{item.staffName}}
											<span>{{item.approvalOpinion}}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</van-tab>
			</van-tabs>
		</div>
		<div class="bottom-fixed">
			<!--      查看电子合同-->
			<div class="electric-contract" @click="openElectricContract" v-if="this.contractInfo.electricContractPath && this.contractInfo.electricContractPath != ''">
				<img class="electric-contract-photo" src="../../assets/images/electric-contract.png">
				<div>
					电子合同
				</div>
			</div>
			<div class="bottom_btn">
				<van-button size="large" class="btn" @click="showMobile()">{{mode=='1'?type=='2'?'联系业主':'联系租客':'联系管家'}}</van-button>
			</div>
		</div>

		<!--    <div class="bottom_btn">-->
		<!--      <van-button size="large" class="btn" @click="showMobile()">{{type=='2'?'联系业主':'联系租客'}}</van-button>-->
		<!--    </div>-->
		<!--拨打电话弹窗-->
		<dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName" :dialogTitle="mode=='1'?type=='2' ? dialogOTitle:dialogRTitle:dialogDTitle" :mobileShow="isMobileShow" @hideDialog="hideDialog"> </dial-mobile>
	</div>
</template>
<script>
	import BScroll from "better-scroll";
	import {
		NavBar,
		Tab,
		Tabs,
		Row,
		Col,
		Divider,
		Tag,
		Image as VanImage,
		Button,
		ImagePreview,
		Popup,
		Toast
	} from "vant";

	import {
		findOwnerContractInfo,
		findContractproperty,
		queryOwnerContractBill,
		findContractHistoryDetail,
		findApprovalHistory,
		findElectronicContractUrl, queryContractProperty, queryRenterContractBill
	} from "../../getData/getData";
	import {
		checkAndroid, checkIOS,
		responseUtil
	} from "../../libs/rongxunUtil";
	import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile';
	import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
	/********判断H5页面是否在web容器中 start*********/
	function openInWebview () {/* 返回true或false; */
		var ua = navigator.userAgent.toLowerCase();
		if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
			return false;
		} else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
			return false;
		} else if (ua.match(/WeiBo/i) == "weibo") {
			return false;
		} else {
			if (ua.match(/Android/i) != null) {
				return ua.match(/browser/i) == null;
			} else if (ua.match(/iPhone/i) != null) {
				return ua.match(/safari/i) == null;
			} else {
				return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
			}
		}
	}
	/********判断H5页面是否在web容器中 start*********/
	export default {
		data() {
			return {
				// 0已退房 1租期中
				contractId: '', //合同id
				contractHistoryId: '', //合同历史id
				ownerOrRenterContractId: '', //业主或者租客合同id
				mode: '', //1代表工作台里的
				historyType: '',
				state: 1,
				dialogOTitle: '联系业主',
				dialogRTitle: '联系租客',
				dialogDTitle: '联系管家',
				managePhone: '',
				manageName: '',
				//拨打电话弹窗
				isMobileShow: false,
				temporaryOwnerMobile: '',
				temporaryOwnerName: '',
				type: '',
				contractStatus: '',
				contractTypeVal: '',
				//业主合同
				contractInfo: '',
				userDefaultHeadImg: require("../../assets/images/default-headImg.jpg"),
				ownerPhoto: [], //业主身份图片信息
				proxyPhoto: [], //代理人图片信息
				coOwnerPhoto: [], //共有权人信息
				enclosurePropertyPicList: [], //产权证信息
				outLinecontratPicList: [], //线下合同信息
				//展示配置物品图片信息
				propertyDetailPhotos: [],
				leasePropertyDetailPhotos: [], //租约变更的配置物品图片信息
				show: false, //显示合同的弹出层
				showLease: false, //显示租约的弹出层
				showProperty: false, //是否显示交割单
				showLeaseProperty: false, //是否显示租约的交割单
				isShowContractPropertyDetail: false, //是否显示合同方面的物品详情
				isShowLeasePropertyDetail: false, //是否显示租约变更的物品详情
				showLeaseBack: false, //是否显示退租信息
				globaluserId: '',
				ownerInfo: {}, //业主信息
				title: "",
				tabActive: 0,
				timeLineItems: [],
				noFreeList: [], //免租期列表
				approvalHistroyList: [], //审批历史记录
				// 租客
				tenant: {
					headPortrait: 'https://img.yzcdn.cn/vant/cat.jpeg',
				},
				// 合同
				contract: {},
				//物业交割信息
				DO: {}, //合同方面的
				LO: {}, //租约变更方面的
				bill: [],
				contractProperty:'',
				contractPropertyDetail:[],
				tenantContractProperty:'',
				tenantPropertyDetail:[],
				ww1:true,
				ww2:true,
				withoutAny: {

				},
			}
		},
		created() {
			this.contractId = this.$route.query.contractId
			this.contractHistoryId = this.$route.query.contractHistoryId
			this.ownerOrRenterContractId = this.$route.query.ownerOrRenterContractId
			this.type = this.$route.query.type
			this.contractStatus = this.$route.query.contractStatus
			this.contractTypeVal = this.$route.query.contractTypeVal
			this.mode = this.$route.query.mode || ''
			this.historyType = this.$route.query.historyType
			this.managePhone = this.$route.query.managePhone || ''
			this.manageName = this.$route.query.manageName || ''
			this.initcontractInfo()
			this.findContractHistoryDetail()
			this.findApprovalHistory()
		},
		methods: {
			//返回上一层
			leftReturn() {
				this.$router.go(-1);
			},
			//拨打电话
			showMobile() {
				let that = this;
				if(that.mode=='1'){
					if (this.type == 1) {
						that.temporaryOwnerMobile = this.contractInfo.renterMobile;
						that.temporaryOwnerName = this.contractInfo.renterName;
					} else {
						that.temporaryOwnerMobile = this.contractInfo.ownerMobile;
						that.temporaryOwnerName = this.contractInfo.ownerName;
					}
				} else {
					that.temporaryOwnerMobile = that.managePhone;
					that.temporaryOwnerName = that.manageName;
				}
				that.isMobileShow = !that.isMobileShow;
			},
			//关闭拨打电话弹窗
			hideDialog() {
				let that = this;
				that.isMobileShow = false;
			},
			previewImg(start) {
				ImagePreview({
					images: this.ownerPhoto,
					startPosition: start,
					onClose() {
						// do something
					}
				});
			},
			//previewCoImg
			previewCoImg(start) {
				ImagePreview({
					images: this.proxyPhoto,
					startPosition: start,
					onClose() {
						// do something
					}
				});
			},
			enclosureviewImg(start) {
				ImagePreview({
					images: this.enclosurePropertyPicList,
					startPosition: start,
					onClose() {
						// do something
					}
				});
			},
			outLinecontratviewImg(start) {
				ImagePreview({
					images: this.outLinecontratPicList,
					startPosition: start,
					onClose() {
						// do something
					}
				});
			},
			previewPropertyDetailPhotos(start) {
				ImagePreview({
					images: this.propertyDetailPhotos,
					startPosition: start,
					onClose() {
						// do something
					}
				});
			},
			//previewLeasePropertyDetailPhotos
			previewLeasePropertyDetailPhotos(start) {
				ImagePreview({
					images: this.leasePropertyDetailPhotos,
					startPosition: start,
					onClose() {
						// do something
					}
				});
			},
			//类型选择变换
			onClick(name, title) {
				//console.log(title)
				if (title == '账单'){
					if(this.type == '1'){
						this.queryRenterContractBill()
					}else if(this.type == '2'){
						this.queryOwnerContractBill()
					}
				}
				if (title == '交割') {
					// this.initContractproperty()
					// this.initContractpropertyAn()
					this.queryContractProperty()
				}
			},
			//业主合同详情页面初始化
			initcontractInfo() {
				let that = this
				let initData = {}
				//let id = that.$route.params.contractId
				initData.ownerContractId = that.contractId
				findOwnerContractInfo(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						console.log(response)
						that.contractInfo = response.data.data.data
						let photo = response.data.data.data.photo
						that.noFreeList = response.data.data.data.contractnofeeList || []
						let list = []
						that.enclosurePropertyPicList = response.data.data.data.enclosurePropertyPicList || []
						for (let i in that.enclosurePropertyPicList) {
							list.push(that.enclosurePropertyPicList[i].path)
						}
						that.enclosurePropertyPicList = list
						list = []
						that.outLinecontratPicList = response.data.data.data.outLinecontratPicList || []
						for (let i in that.outLinecontratPicList) {
							list.push(that.outLinecontratPicList[i].path)
						}
						that.outLinecontratPicList = list
						if (photo) {
							//业主身份信息
							if (photo.lesseeOrOwnerIdFront) {
								that.ownerPhoto.push(photo.lesseeOrOwnerIdFront)
							} else {
								that.ownerPhoto.push(require('../../assets/images/default_img_4.png'))
							}
							if (photo.lesseeOrOwnerIdBack) {
								that.ownerPhoto.push(photo.lesseeOrOwnerIdBack)
							} else {
								that.ownerPhoto.push(require('../../assets/images/default_img_5.png'))
							}
							if (photo.lesseeOrOwnerIdHand) {
								that.ownerPhoto.push(photo.lesseeOrOwnerIdHand)
							} else {
								that.ownerPhoto.push(require('../../assets/images/default_img_3.png'))
							}
							//代理人身份信息
							if (photo.togetherOrEntrustIdFront) {
								that.proxyPhoto.push(photo.togetherOrEntrustIdFront)
							} else {
								that.proxyPhoto.push(require('../../assets/images/default_img_4.png'))
							}
							if (photo.togetherOrEntrustIdBack) {
								that.proxyPhoto.push(photo.togetherOrEntrustIdBack)
							} else {
								that.proxyPhoto.push(require('../../assets/images/default_img_5.png'))
							}
							if (photo.togetherOrAccessories1) {
								that.proxyPhoto.push(photo.togetherOrAccessories1)
							} else {
								that.proxyPhoto.push(require('../../assets/images/default_img_6.png'))
							}
							if (photo.togetherOrAccessories2) {
								that.proxyPhoto.push(photo.togetherOrAccessories2)
							} else {
								that.proxyPhoto.push(require('../../assets/images/default_img_6.png'))
							}
							//共有权人身份信息
							if (photo.commonOwnerIdCardFront) {
								that.coOwnerPhoto.push(photo.commonOwnerIdCardFront)
							} else {
								that.coOwnerPhoto.push(require('../../assets/images/default_img_4.png'))
							}
							if (photo.commonOwnerIdCardBack) {
								that.coOwnerPhoto.push(photo.commonOwnerIdCardBack)
							} else {
								that.coOwnerPhoto.push(require('../../assets/images/default_img_5.png'))
							}
						} else {
							//什么图片都没有 就全部显示默认的图片
							that.ownerPhoto.push(require('../../assets/images/default_img_4.png'))
							that.ownerPhoto.push(require('../../assets/images/default_img_5.png'))
							that.ownerPhoto.push(require('../../assets/images/default_img_3.png'))
							that.proxyPhoto.push(require('../../assets/images/default_img_4.png'))
							that.proxyPhoto.push(require('../../assets/images/default_img_5.png'))
							that.proxyPhoto.push(require('../../assets/images/default_img_6.png'))
							that.proxyPhoto.push(require('../../assets/images/default_img_6.png'))
						}
						if (that.enclosurePropertyPicList.length > 0) {
							that.$nextTick(() => {
								if (!that.scroll) {
									that.scroll = new BScroll(that.$refs['wrapperBox1'], {
										scrollX: true,
										eventPassthrough: 'vertical'
									})
								}
							})
						}
						if (that.outLinecontratPicList.length > 0) {
							that.$nextTick(() => {
								if (!that.scroll1) {
									that.scroll1 = new BScroll(that.$refs['wrapperBox2'], {
										scrollX: true,
										eventPassthrough: 'vertical'
									})
								}
							})
						}
						//初始化业主信息
						//  that.queryPersonDetails()
					})
				})
			},
			//查询用户信息
			// queryPersonDetails(){
			//   let that = this
			//   let initData={}
			//   initData.user_id = globaluserId()
			//   queryPersonDetails(initData).then(function (response) {
			//     responseUtil.dealResponse(that, response, () => {
			//         //console.log(response.data.data)
			//         that.ownerInfo = response.data.data
			//     })
			//   })
			// },
			//zzy
			queryContractProperty(){
				let that = this
				let initData = {}
				let contractId = that.contractId
				initData.contract_id = contractId
				queryContractProperty(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						console.log(response.data.data)
						if(!response.data.data.contractProperty){
							that.ww1 = false
						}
						if(!response.data.data.tenantContractProperty){
							that.ww2 = false
						}
						that.contractProperty = response.data.data.contractProperty
						that.contractPropertyDetail=response.data.data.contractPropertyDetail
						if(response.data.data.tenantContractProperty){
							that.tenantContractProperty=response.data.data.tenantContractProperty[0]
							that.tenantPropertyDetail=response.data.data.tenantPropertyDetail
						}


					})
				})
			},


			//物业交割单合同方面数据加载
			initContractproperty() {
				let that = this
				let initData = {}
				let contractId = that.contractId
				initData.contractId = contractId
				findContractproperty(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						//console.log(response.data.data.contractProperty)
						let info = response.data.data.contractProperty
						if (info) {
							that.DO = info
							//console.log(that.DO)
							that.showProperty = true
							if (that.DO.contractPropertyDetail.length > 0) {
								that.isShowContractPropertyDetail = true
							}
						}
					})
				})
			},

			//物业交割单租约变更方面数据加载
			initContractpropertyAn() {
				let that = this
				let initData = {}
				let contractId = that.contractId
				let contractHistoryId = that.contractHistoryId
				if (contractHistoryId) {
					initData.contracthistoryId = contractHistoryId
					initData.isByContractHistory = true
				}
				initData.contractId = contractId
				findContractproperty(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						//console.log(response.data.data)
						let info = response.data.data.contractProperty
						if (info) {
							that.LO = info
							// console.log(that.LO)
							that.showLeaseProperty = true
							if (that.LO.contractPropertyDetail.length > 0) {
								that.isShowLeasePropertyDetail = true
							}
						}
					})
				})
			},

			//业主合同账单数据加载
			queryOwnerContractBill() {
				let that = this
				let initData = {}
				let contractId = that.contractId
				initData.contractId = contractId
				initData.currentPage = "1"
				initData.numberPage = "200"
				queryOwnerContractBill(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						console.log(response.data.data.data)
						that.bill = response.data.data.data || []
					})
				})
			},

			//租客合同账单数据加载
			queryRenterContractBill() {
				let that = this
				let initData = {}
				let contractId = that.contractId
				initData.contractId = contractId
				initData.currentPage = "1"
				initData.numberPage = "200"
				queryRenterContractBill(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						console.log(response.data.data.data)
						that.bill = response.data.data.data || []
					})
				})
			},
			//业主合同退租数据加载
			findContractHistoryDetail() {
				let that = this
				let initData = {}
				initData.id = this.contractHistoryId
				initData.ownerContractId = this.contractId
				findContractHistoryDetail(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						// debugger
						console.log(response.data.data.rentInfo)
						let info = response.data.data.rentInfo
						if (info) {
							//console.log(that.contractHistoryId)
							that.withoutAny = info
							that.showLeaseBack = true
						}
					})
				})
			},
			//业主合同审批信息记录初始化
			findApprovalHistory() {
				let that = this
				let initData = {}
				let contractId = that.contractId
				initData.currentPage = '1'
				initData.numberPage = '200'
				initData.contractId = contractId
				findApprovalHistory(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						//console.log(response.data.data)
						that.approvalHistroyList = response.data.data.data || []
					})
				})
			},

			//点击配置物品详情按钮
			showPropertyDetailPhotos(item) {
				// debugger
				//this.propertyDetailPhotos
				/*if(!item.sublist[0].photoList){
					Toast('暂无实物图')
				}else{
					this.propertyDetailPhotos = []
					//console.log(item.sublist[0].photoList)
					let list = item.sublist[0].photoList
					for (let i = 0; i < list.length; i++) {
						this.propertyDetailPhotos.push(list[i].path)
					}
					this.show = true
				}*/
				if(!item.photoList){
					Toast('暂无实物图')
				}else{
					this.propertyDetailPhotos = []
					//console.log(item.sublist[0].photoList)
					let list = item.photoList
					for (let i = 0; i < list.length; i++) {
						this.propertyDetailPhotos.push(list[i].path)
					}
					this.show = true
				}
			},

			//点击配置物品详情按钮
			showLeasePropertyDetailPhotos(item) {
				//this.propertyDetailPhotos
				if(!item.sublist[0].photoList){
					Toast('暂无实物图')
				}else{
					this.leasePropertyDetailPhotos = []
					//console.log(item.sublist[0].photoList)
					let list = item.sublist[0].photoList
					for (let i = 0; i < list.length; i++) {
						this.leasePropertyDetailPhotos.push(list[i].path)
					}
					this.showLease = true
				}

			},

			//日期格式化方法
			timestampToTime(timestamp) {
				let date = new Date(timestamp)
				let Y = date.getFullYear() + '-'
				let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
				let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
				return Y + M + D
			},
			//计算总免周期的方法
			getTotalNoFreeMonth() {
				let total = 0
				if (this.noFreeList.length > 0) {
					for (let i = 0; i < this.noFreeList.length; i++) {
						total += parseInt(this.noFreeList[i].noFeeMonthLong)
					}
					return total
				}
				return ''
			},			
			//计算总免周期的方法
			getTotalNoFreeDays() {
				let total = 0
				if (this.noFreeList.length > 0) {
					for (let i = 0; i < this.noFreeList.length; i++) {
						total += parseInt(this.noFreeList[i].noFeeTimeLong)
					}
					return total
				}
				return ''
			},
			//查看电子合同
			openElectricContract() {
				var thit = this
				if (this.contractInfo.electricContract_id && this.contractInfo.electricContract_id != '') {
					let queryData = {};
					queryData.contractId = this.contractInfo.electricContract_id;
					findElectronicContractUrl(queryData).then(function(response) {
						responseUtil.dealResponse(thit, response, () => {
							if (response.data.code == 0) {
								let electronicContractUrl = response.data.data.url;
									thit.$router.push({
										name:'electronicContract',
										query:{
											electronicContractUrl: electronicContractUrl,
										}
									});
							} else {
								responseUtil.alertMsg(this, response.data.msg);
							}
						})
					})
				} else {
					responseUtil.alertMsg(this, '没有电子合同！');
				}
			},
		},
		components: {
			[NavBar.name]: NavBar,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Row.name]: Row,
			[Col.name]: Col,
			[Tag.name]: Tag,
			[Divider.name]: Divider,
			[VanImage.name]: VanImage,
			[Button.name]: Button,
			[BScroll.name]: BScroll,
			[ImagePreview.name]: ImagePreview,
			[Popup.name]: Popup,
			rxNavBar,
			dialMobile
		}
	};
</script>
<style lang="less" scoped>
	@redius: 8px;
	@spanColor: rgba(102, 102, 102, 1);

	.tu_hide {
		display: none;
		width: 19px;
		height: 19px;
		float: right;
	}

	.tu_show {
		display: block;
		width: 19px;
		height: 19px;
		float: right;
	}

	.unpay {
		width: 20px;
		height: 20px;
		float: right;
	}

	.potential {
		width: 100%;
		background-color: #f8f8f8;
		font-size: 12px;
		box-sizing: border-box;
		overflow: hidden;

		p {
			margin: 0;
		}

		span {
			&.hasPadding {
				padding: 0 5px;
			}
		}

		.panel {
			/*width: 345px;*/
			width: 92%;
			margin: 15px auto;
			background-color: rgb(255, 255, 255);
			border-radius: @redius;
			position: relative;
			font-size: 15px;
			text-align: left;
			overflow: hidden;
			padding: 15px;
			box-sizing: border-box;

			&.withoutMargin {
				margin: 3px auto;
			}

			p {
				margin: 6px 0;
				font-weight: bolder;

				span {
					font-weight: normal;
					color: rgb(151, 151, 151);
				}
			}

			.state {
				position: absolute;
				top: 0;
				left: 0;
				font-size: 20px;
				font-weight: bold;
				height: 31px;
				line-height: 33px;
				width: 60px;
				display: block;
				background-color: rgb(163, 153, 153);
				border-top-left-radius: 16px;
				border-bottom-right-radius: 16px;
				color: rgb(255, 255, 255);
				text-align: center;
				transform: scale(0.5);
				transform-origin: 0% 0%;
				font-family: sans-serif;
				padding: 0 10px;


				&.stateGreen {
					background-image: linear-gradient(to right, #66CB94, #28D3B0);
				}

				&.stateRed {
					background-image: linear-gradient(to right, #E02020, #FF431B);
				}

				&.stateGray {
					background-image: linear-gradient(to right, #999999, #999999);
				}

				&.stateOrange {
					background-image: linear-gradient(to right, #FFC274, #FF5D3B);
				}
			}

			.title {
				font-weight: bolder;
				color: rgb(0, 0, 0);
				font-size: 15px;
				/*padding: 20px 15px 15px;*/
				border-bottom: 1px solid rgb(250, 250, 250);

				span {
					font-weight: normal;
					color: rgba(153, 153, 153);
				}
			}

			.info {
				/*padding: 15px;*/
				padding-top: 12px;
				padding-bottom: 12px;
				border-bottom: 1px solid rgb(250, 250, 250);

				&:first-of-type {
					padding-top: 0;
				}

				&:last-of-type {
					border-bottom: none;
					padding-bottom: 0;
				}

				.tenant {
					font-size: 18px;
					display: flex;
					align-items: center;
					.male {
						font-size: 12px;
						color: rgba(86, 155, 236, 1);
						margin-left: 10px;
					}

					.female {
						font-size: 12px;
						color: rgba(255, 140, 219, 1);
						margin-left: 10px;
					}
				}

				.DO_link {
					float: right;
					color: rgba(86, 155, 236, 1);
					cursor: pointer;
					font-size: 12px;
				}
			}

			&.refund {
				p {
					line-height: 24px;
					padding: 0;
					margin: 0;
				}
			}
		}

		.span-description {
			font-size: 15px;
			font-weight: normal;
			color: rgba(153, 153, 153);
		}

		.bill {
			margin-top: 15px;

			.bill_title {
				font-size: 12px;
				background-color: rgb(241, 241, 241);
				color: rgb(155, 155, 155);
				padding: 1px 15px;
			}

			.bill_panel {
				background-color: rgb(255, 255, 255);


				.bill_info {
					position: relative;

					/*padding: 15px;*/
					padding: 0 15px;
					border-bottom: 1px solid rgb(250, 250, 250);

					.img_icon {
						position: absolute;
						top: 0;
						right: -6px;
					}

					&:nth-last-child {
						border-bottom: none;
					}

					.bill_money {
						font-size: 15px;

						&.unreceived {
							opacity: .3;
						}
					}

					p {
						font-weight: bolder;
						font-size: 14px;
						padding-top: 11px;

						&:last-of-type {
							padding-bottom: 10px;
						}
					}

					span {
						font-weight: normal;
						color: rgb(151, 151, 151);
					}
				}
			}
		}

		.billPayTime{
			color: rgb(151, 151, 151);
			height: 18px;
			line-height: 18px;
		}

		.application_time {
			text-align: right;
			width: 345px;
			font-size: 12px;
			color: rgb(136, 136, 136);
			padding: 10px 0 5px;
		}

		.tabWrapper {
			width: 100%;
			overflow: hidden;
			margin-bottom: 95px;
		}

		.bottom_btn {
			margin-left: 20px;
			/*padding:10px 15px;*/
			/*margin-left: 4.25rem;*/
			margin-left: 5.55rem;

			.btn {
				width: 120px;
				margin-top: 10px;
				border-radius: @redius;
				background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
				background: -webkit-linear-gradient(left,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Safari 5.1 - 6.0 */
				background: -o-linear-gradient(right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Opera 11.1 - 12.0 */
				background: -moz-linear-gradient(right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Firefox 3.6 - 15 */
				background: linear-gradient(to right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				color: rgb(255, 255, 255);
				font-size: 16px;
			}
		}

		// 图片墙
		.swiper_show {
			width: 325px;
			position: relative;
			margin: 15px 10px;
			height: 75px;

			.content {
				position: absolute;
				display: flex;
				justify-content: space-around;
				left: 0px;

				.imgWrap {
					margin-right: 10px;
				}
			}

			.num {
				position: absolute;
				left: 4px;
				top: 6px;
				font-size: 10px;
				background-color: rgb(0, 0, 0);
				opacity: 0.5;
				color: rgb(255, 255, 255);
				padding: 2px 6px;
				display: inline-block;
				border-radius: 10px;
				font-weight: bolder;
				line-height: 10px;
			}
		}

		// 时间线
		.approve {
			margin-top: 20px;

			.time_line {
				.item {
					display: flex;
					align-items: stretch;
					justify-content: space-between;

					.left {
						width: 20px;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: 15px;
						/*margin-right: 5px;*/

						.line {
							width: 1px;
							background-color: rgb(255, 75, 39);
							position: absolute;

							&.line_top {
								top: 0px;
								height: ceil(50%-8px);

								&.first_top {
									background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
									/* Safari 5.1 - 6.0 */
									background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
									/* Opera 11.1 - 12.0 */
									background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
									/* Firefox 3.6 - 15 */
									background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
								}
							}

							&.line_bottom {
								bottom: 0;
								height: ceil(50%+10px);
							}
						}

						.point {
							position: relative;
							width: 9px;
							height: 9px;
							border-radius: 50%;
							border: 1px solid rgb(255, 75, 39);
							transform: translateY(-50%);
							z-index: 99;
							background-color: rgb(255, 255, 255);

							&::after {
								content: '';
								display: block;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								background-color: rgb(255, 75, 39);
								width: 5px;
								height: 5px;
								border-radius: 50%;
							}
						}


					}

					.right {
						width: 100%;
						padding-bottom: 20px;

						.approve_panel {
							margin: 0 15px;
							border-radius: @redius;
							background-color: rgb(255, 255, 255);
							padding: 10px;

							p {
								padding-top: 10px;
								font-weight: bolder;

								&:last-of-type {
									padding-bottom: 10px;
								}

								span {
									font-weight: normal;
									color: rgb(82, 82, 82);
								}
							}
						}
					}
				}

			}
		}

		.small_font {
			font-size: 12px !important;
		}

		.floatR {
			float: right !important;
		}

		.total_color {
			color: rgba(255, 93, 59, 1) !important;
		}
	}

	/* 未收图标 */
	.laber {
		width: 0px;
		height: 0px;
		border-top: 0px solid #FE8D68;
		border-right: 44.6px solid #FE8D68;
		border-bottom: 44.6px solid transparent;
		border-left: 30px solid transparent;
		position: absolute;
		top: 0px;
		right: 0px;


	}

	/* 旋转文字 */
	.laberText {
		width: 33px;
		height: 47px;
		transform: rotate(45deg);
		font-size: 12px;
		color: #ffffff;
	}

	.rentPriceA {
		color: rgba(252, 213, 204, 1);
		font-weight: 500;
	}

	/*免租期样式*/
	.freeTime {
		width: 345px;
		margin: 15px 15px 15px 15px;
		height: auto;
		border-radius: 8px;
		background-color: white;
		overflow: hidden;
	}

	.totalTime {
		width: 345px;
		margin: 0 15px 15px 15px;
		height: 45px;
		border-radius: 8px;
		background-color: white;
		display: flex;
		align-items: center;
	}

	.freeOne {
		height: auto;
		width: 100%;
		border-bottom: 1px solid #f8f8f8;
		overflow: hidden;
	}

	.freeOne-title {
		margin: 5px 0 5px 20px;
		color: red;
		font-weight: bold;
		font-size: 15px;
	}

	.freeOne-title span {
		color: black;
		font-weight: normal;
	}

	.freeOne-time {
		margin: 5px 0 5px 20px;
		color: black;
		font-weight: bold;
		font-size: 15px;
	}

	.freeOne-time span {
		color: black;
		font-weight: normal;
	}

	.totalTitle {
		margin-left: 20px;
		font-weight: bold;
		font-size: 15px;
		height: 20px;
		line-height: 22px;
		width: fit-content;
	}

	.totalLine {
		width: 1px;
		height: 15px;
		margin: 0 8px;
		background-color: gray;
	}

	.totalDays {
		width: 120px;
		height: 20px;
		text-align: left;
		font-size: 15px;
		font-weight: bold;
		line-height: 22px;
		color: red;
	}

	.totalDesc {
		color: gray;
		text-align: left;
		font-size: 15px;
		height: 20px;
		line-height: 22px;
		width: fit-content;
		margin-left: 100px;
	}

	/*交割单标题文字*/
	.contractOrLeaseChange {
		display: inline-block;
		color: red;
		font-weight: bold;
		margin: 15px 0 0 15px;
		font-size: 15px;
	}

	//电子合同
	.electric-contract {
		float: left;
		height: 60px;
		width: 70px;
		margin-top: 0.25rem;
		margin-left: 35px;
		font-size: 14px;
	}

	//电子合同图片
	.electric-contract-photo {
		margin-top: 5px;
		margin-left: 16px;
	}

	.bottom-fixed {
		position: fixed;
		border-top: 1px solid rgb(243, 243, 243);
		width: 100%;
		height: 1.86667rem;
		bottom: 0px;
		background-color: white;
	}

	.bz{
		font-size: 14px;
		font-weight: 800;
		margin-bottom: 5px;
	}
</style>
